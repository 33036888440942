import React, { Component } from "react"
import { BrowserView, MobileView } from "react-device-detect"
import {withStyles} from "@mui/styles"

import {Typography, Box, Divider} from "@mui/material"

const style = theme => ({
  root: {
    height: "100% !important"
  }, 
  bRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  bImageSlide: {
    width :"60%",
    backdropFilter: "blur(10px)",
    padding: theme.spacing(2),
    //animation: `$moveUpwards 200ms ${theme.transitions.easing.easeInOut}`
  },
  bLegalText: {
    height: '60vh',
    marginTop: theme.spacing(4),
    padding: theme.spacing(1),
    backdropFilter: "blur(20px) brightness(50%)",
    borderRadius: "8px",
    animation: `$slowShow 800ms ${theme.transitions.easing.easeInOut}`,
    overflowY: 'auto',
  },
  bDivider: {
    background: "#ffffff10",
  },
  bTextP: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bTextEnd: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  "@keyframes moveUpwards": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    }
  },
  "@keyframes slowShow": {
    "0%": {
      opacity: 0,
      backdropFilter: "blur(0px) brightness(100%)",
      borderRadius: "0px",
    },
    "100%": {
      opacity: 1,
      backdropFilter: "blur(20px) brightness(50%)",
      borderRadius: "8px",
    }
  },
  mRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  mImageSlide: {
    width :"80%",
    backdropFilter: "blur(10px)",
    padding: theme.spacing(2),
    //animation: `$moveUpwards 200ms ${theme.transitions.easing.easeInOut}`
  },
  mLegalText: {
    height: '70vh',
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    backdropFilter: "blur(20px) brightness(50%)",
    borderRadius: "8px",
    animation: `$slowShow 800ms ${theme.transitions.easing.easeInOut}`,
    overflowY: 'auto',
  },
  mDivider: {
    background: "#ffffff10"
  },
  mTextP: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mTextEnd: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
})

class LegalComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      language: props.language
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ language: nextProps.language });  
  }

  render() {
    const {classes} = this.props

    let legalBrowser = null
    let legalMobile = null

    if (this.state.language === "de") {
      legalBrowser = (
        <Box className={classes.bLegalText}>
          <Typography variant="h4">Impressum</Typography>
          <Typography variant="subtitle1">Angaben gemäß § 5 TMG </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Tesflour GbR<br/>Hochstraße 17<br/>85122 Hitzhofen<br/>Deutschland<br/><br/>Vertreten durch:<br/>Jan-Niklas Dittert, Lorenz Kaufmann, Lukas Schröder 
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            <Typography variant="h5">Kontakt</Typography>
            E-Mail: info@tesflour.com<br/>Telefon: +4915251510151
          </Typography>
          <Divider light={true} className={classes.bDivider}>Legal</Divider>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            <Typography variant="h5">Verbraucherstreitbeilegung / Universalschlichtungsstelle</Typography>
              Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
          </Typography>
          <Typography variant="h5">Haftung für Inhalte</Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </Typography>
          <Typography variant="h5">Haftung für Links</Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen. 
          </Typography>
          <Typography variant="h5">Urheberrecht </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </Typography>
        </Box>
      )
      legalMobile = (
        <Box className={classes.mLegalText}>
          <Typography variant="h4">Impressum</Typography>
          <Typography variant="subtitle1">Angaben gemäß § 5 TMG </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Tesflour GbR<br/>Hochstraße 17<br/>85122 Hitzhofen<br/>Deutschland<br/><br/>Vertreten durch:<br/>Jan-Niklas Dittert, Lorenz Kaufmann, Lukas Schröder 
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            <Typography variant="h5">Kontakt</Typography>
            E-Mail: info@tesflour.com<br/>Telefon: +491525151015 
          </Typography>
          <Divider light={true} className={classes.mDivider}>Legal</Divider>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            <Typography variant="h5">Verbraucherstreitbeilegung / Universalschlichtungsstelle</Typography>
              Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
          </Typography>
          <Typography variant="h5">Haftung für Inhalte</Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </Typography>
          <Typography variant="h5">Haftung für Links</Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen. 
          </Typography>
          <Typography variant="h5">Urheberrecht </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </Typography>
        </Box>
      )
    } else if (this.state.language === "en") {
      legalBrowser = (
        <Box className={classes.bLegalText}>
          <Typography variant="h4">Legal notice</Typography>
          <Typography variant="subtitle1">Information according to § 5 TMG </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Tesflour GbR<br/>Hochstraße 17<br/>85122 Hitzhofen<br/>Germany<br/><br/>Represented by:<br/>Jan-Niklas Dittert, Lorenz Kaufmann, Lukas Schröder 
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            <Typography variant="h5">Contact</Typography>
            E-Mail: info@tesflour.com<br/>Phone: +4915251510151
          </Typography>
          <Divider light={true} className={classes.bDivider}>Legal</Divider>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            <Typography variant="h5">Consumer Dispute Resolution / Universal Arbitration Board</Typography>
              We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.
          </Typography>
          <Typography variant="h5">Liability for content</Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            As a service provider, we are responsible for our own content on these pages in accordance with general legislation pursuant to Section 7 (1) of the German Telemedia Act (TMG). However, according to §§ 8 to 10 TMG, we are not obliged as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity.
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Obligations to remove or block the use of information in accordance with general laws remain unaffected by this. However, liability in this regard is only possible from the time of knowledge of a concrete infringement. If we become aware of corresponding infringements, we will remove this content immediately.
          </Typography>
          <Typography variant="h5">Liability for links</Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognisable at the time of linking. However, permanent monitoring of the content of the linked pages is not reasonable without concrete indications of a legal violation. If we become aware of any infringements of the law, we will remove such links immediately.
          </Typography>
          <Typography variant="h5">Copyright </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            The content and works created by the site operators on these pages are subject to German copyright law. Duplication, processing, distribution and any kind of exploitation outside the limits of copyright law require the written consent of the respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is marked as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such content immediately.
          </Typography>
        </Box>
      )
      legalMobile = (
        <Box className={classes.mLegalText}>
          <Typography variant="h4">Legal notice</Typography>
          <Typography variant="subtitle1">Information according to § 5 TMG </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Tesflour GbR<br/>Hochstraße 17<br/>85122 Hitzhofen<br/>Germany<br/><br/>Represented by:<br/>Jan-Niklas Dittert, Lorenz Kaufmann, Lukas Schröder 
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            <Typography variant="h5">Contact</Typography>
            E-Mail: info@tesflour.com<br/>Phone: +491525151015 
          </Typography>
          <Divider light={true} className={classes.mDivider}>Legal</Divider>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            <Typography variant="h5">Consumer Dispute Resolution / Universal Arbitration Board</Typography>
              We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.
          </Typography>
          <Typography variant="h5">Liability for content</Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            As a service provider, we are responsible for our own content on these pages in accordance with general legislation pursuant to Section 7 (1) of the German Telemedia Act (TMG). However, according to §§ 8 to 10 TMG, we are not obliged as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity.
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Obligations to remove or block the use of information in accordance with general laws remain unaffected by this. However, liability in this regard is only possible from the time of knowledge of a concrete infringement. If we become aware of corresponding infringements, we will remove this content immediately.
          </Typography>
          <Typography variant="h5">Liability for links</Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognisable at the time of linking. However, permanent monitoring of the content of the linked pages is not reasonable without concrete indications of a legal violation. If we become aware of any infringements of the law, we will remove such links immediately.
          </Typography>
          <Typography variant="h5">Copyright </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            The content and works created by the site operators on these pages are subject to German copyright law. Duplication, processing, distribution and any kind of exploitation outside the limits of copyright law require the written consent of the respective author or creator. Downloads and copies of this site are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is marked as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such content immediately.
          </Typography>
        </Box>
      )
    }

    return(
      <div className={classes.root}>
        <BrowserView className={classes.bRoot}> 
          <img src={"/img/logo.png"} alt={"Tesflour Logo"} className={classes.bImageSlide} />
          {legalBrowser}
        </BrowserView>
        <MobileView className={classes.mRoot}>
          <img src={"/img/logo.png"} alt={"Tesflour Logo"} className={classes.mImageSlide} />
          <Typography variant="h3" fontWeight={200}>Legal</Typography>
          {legalMobile}
        </MobileView>
      </div>
    )
  }

}

export default withStyles(style)(LegalComponent)
