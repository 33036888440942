import React, { Component } from "react"
import { BrowserView, MobileView } from "react-device-detect"
import {Link} from 'react-router-dom';
import { withStyles} from "@mui/styles"

import { Typography, Box, Card, CardContent, CardActions, Divider, Button, Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import { ExpandMore } from "@mui/icons-material"

const style = theme => ({
  root: {
    heigh: "100% !important",
    width: "100%",
  },
  bRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  bImageSlide: {
    width: "60%",
    backdropFilter: "blur(10px)",
    padding: theme.spacing(2),
    //animation: `$moveUpwards 200ms ${theme.transitions.easing.easeInOut}`
  },
  bServiceCards: {
    display: "flex",
    marginTop: theme.spacing(12),
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  bServiceCard: {
    marginLeft: "1vw !important",
    marginRight: "1vw !important",
    padding: "1vw 1vh !important",
    color: "#ffffff !important",
    borderRadius: "8px",
    backgroundColor: '#1e1e1e00 !important',
    backdropFilter: "blur(20px) brightness(50%)",
    animation: `$slowShow 800ms ${theme.transitions.easing.easeInOut}`,
  },
  bCardDivider: {
    marginTop: "2vh !important",
    marginBottom: "2vh !important",
    borderBottom: "2px solid #ffffff !important",
  },
  bCardButton: {
    marginLeft: "auto",
    marginRight: theme.spacing(0),
    borderRadius: "10px",
    textDecoration: "none",
    //border: "1px solid #ffffff"
  },
  "@keyframes moveUpwards": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    }
  },
  "@keyframes slowShow": {
    "0%": {
      opacity: 0,
      backdropFilter: "blur(0px) brightness(100%)",
      borderRadius: "0px",
    },
    "100%": {
      opacity: 1,
      backdropFilter: "blur(20px) brightness(50%)",
      borderRadius: "8px",
    }
  },
  mRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  mImageSlide: {
    width: "80%",
    backdropFilter: "blur(10px)",
    padding: theme.spacing(2),
    //animation: `$moveUpwards 200ms ${theme.transitions.easing.easeInOut}`
  },
  mServiceCards: {
    display: "flex",
    marginTop: theme.spacing(2),
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  mServiceCard: {
    marginTop: "1vw !important",
    marginBottom: "1vw !important",
    padding: "1vw 1vh !important",
    color: "#ffffff !important",
    borderRadius: "8px",
    backgroundColor: '#1e1e1e00 !important',
    backdropFilter: "blur(20px) brightness(50%)",
    animation: `$slowShow 800ms ${theme.transitions.easing.easeInOut}`,
  },
  mCardDivider: {
    marginTop: "2vh !important",
    marginBottom: "2vh !important",
    borderBottom: "2px solid #ffffff !important",
  },
  mCardButton: {
    marginLeft: "auto",
    marginRight: theme.spacing(0),
    borderRadius: "10px",
    textDecoration: "none",
    //border: "1px solid #ffffff"
  },
})

class ServicesComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      language: props.language
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ language: nextProps.language });  
  }

  render() {
    const {classes} = this.props

    let servicesBrowser = null
    let servicesMobile = null

    if (this.state.language === "de") {
      servicesBrowser = (
        <Box className={classes.bServiceCards}>
          <Card className={classes.bServiceCard}>
            <CardContent>
              <Typography variant="h5" className={classes.bCardTitle}>DJ Sets / Bookings</Typography>
              <Divider className={classes.bCardDivider}/>
              <Typography variant="body1" component="p">Du brauchst für deinen Abend die passende Musik?</Typography>
              <Typography variant="body1" component="p">Sende uns hier unverbindlich eine Anfrage für ein DJ-Booking für deine Party!</Typography>
              <Typography variant="body1" component="p">Wir können mittlerweile auf 10 Jahre Bühnenerfahrung zurückblicken und wollen für dich das Bestmögliche aus dem Abend herausholen.</Typography>  
            </CardContent>
            <CardActions>
              <Link to='/contact/' className={classes.bCardButton}><Button variant="contained" color="inherit" id="s1"><Typography variant="body2" color={"#000000"}>Anfragen</Typography></Button></Link>
            </CardActions>
          </Card>
          <Card className={classes.bServiceCard}>
            <CardContent>
              <Typography variant="h5" className={classes.bCardTitle}>Verleih Equipment</Typography>
              <Divider className={classes.bCardDivider}/>
              <Typography variant="body1" component="p">Ihr plant eine Party, braucht noch eine Box für den Geburtstag oder seid selber DJ?</Typography>
              <Typography variant="body1" component="p">Gerne senden wir euch eine Liste von unserem Equipment zu.</Typography>
              <Typography variant="body1" component="p">Sende uns hier unverbindlich eine Anfrage für das Verleihen unseres Equipments für deine Party!</Typography>
            </CardContent>
            <CardActions>
              <Link to='/contact/' className={classes.bCardButton}><Button variant="contained" color="inherit" id="s2"><Typography variant="body2" color={"#000000"}>Anfragen</Typography></Button></Link>
            </CardActions>
          </Card>
          <Card className={classes.bServiceCard}>
            <CardContent>
              <Typography variant="h5" className={classes.bCardTitle}>Mixing & Mastering</Typography>
              <Divider className={classes.bCardDivider}/>
              <Typography variant="body1" component="p">Du brauchst Unterstützung in Musikproduktionen oder planst ein Intro für deinen nächsten Podcast oder möchtest einen zusammengestellten Mix?</Typography>
              <Typography variant="body1" component="p">Sende uns hier unverbindlich eine Anfrage und teile uns mit, was du genau brauchst.</Typography>
            </CardContent>
            <CardActions>
              <Link to='/contact/' className={classes.bCardButton}><Button variant="contained" color="inherit" id="s3"><Typography variant="body2" color={"#000000"}>Anfragen</Typography></Button></Link>
            </CardActions>
          </Card>
        </Box>
      )
      servicesMobile = (
        <Box className={classes.mServiceCards}>
          <Accordion className={classes.mServiceCard}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="djsets-bookings" id="djsets-bookings" >
              <Typography variant="h5" className={classes.mCardTitle}>DJ Sets / Bookings</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" component="p">Du brauchst für deinen Abend die passende Musik?</Typography>
              <Typography variant="body1" component="p">Sende uns hier unverbindlich eine Anfrage für ein DJ-Booking für deine Party!</Typography>
              <Typography variant="body1" component="p">Wir können mittlerweile auf 10 Jahre Bühnenerfahrung zurückblicken und wollen für dich das Bestmögliche aus dem Abend herausholen.</Typography>
              <Divider className={classes.mCardDivider}/>
              <Link to='/contact/' className={classes.mCardButton}><Button variant="contained" color="inherit" id="s1"><Typography variant="body2" color={"#000000"}>Anfragen</Typography></Button></Link>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.mServiceCard}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="verleih" id="verleih" >
              <Typography variant="h5" className={classes.mcardTitle}>Verleih Equipment</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" component="p">Ihr plant eine Party, braucht noch eine Box für den Geburtstag oder seid selber DJ?</Typography>
              <Typography variant="body1" component="p">Gerne senden wir euch eine Liste von unserem Equipment zu.</Typography>
              <Typography variant="body1" component="p">Sende uns hier unverbindlich eine Anfrage für das Verleihen unseres Equipments für deine Party!</Typography>
              <Divider className={classes.mCardDivider}/>
              <Link to='/contact/' className={classes.mCardButton}><Button variant="contained" color="inherit" id="s2"><Typography variant="body2" color={"#000000"}>Anfragen</Typography></Button></Link>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.mServiceCard}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="mix-master" id="mix-master" >
              <Typography variant="h5" className={classes.mcardTitle}>Mixing & Mastering</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" component="p">Du brauchst Unterstützung in Musikproduktionen oder planst ein Intro für deinen nächsten Podcast oder möchtest einen zusammengestellten Mix?</Typography>
              <Typography variant="body1" component="p">Sende uns hier unverbindlich eine Anfrage und teile uns mit, was du genau brauchst.</Typography>
              <Divider className={classes.mCardDivider}/>
              <Link to='/contact/' className={classes.mCardButton}><Button variant="contained" color="inherit" id="s3"><Typography variant="body2" color={"#000000"}>Anfragen</Typography></Button></Link>
            </AccordionDetails>
          </Accordion>
        </Box>
      )
    } else if (this.state.language === "en") {
      servicesBrowser = (
        <Box className={classes.bServiceCards}>
          <Card className={classes.bServiceCard}>
            <CardContent>
              <Typography variant="h5" className={classes.bCardTitle}>DJ Sets / Bookings</Typography>
              <Divider className={classes.bCardDivider}/>
              <Typography variant="body1" component="p">You need the right music for your night?</Typography>
              <Typography variant="body1" component="p">Send us a non-binding request for a DJ booking for your party here!</Typography>
              <Typography variant="body1" component="p">We can now look back on 10 years of stage experience and want to get the best possible out of the evening for you.</Typography>  
            </CardContent>
            <CardActions>
              <Link to='/contact/' className={classes.bCardButton}><Button variant="contained" color="inherit" id="s1"><Typography variant="body2" color={"#000000"}>Anfragen</Typography></Button></Link>
            </CardActions>
          </Card>
          <Card className={classes.bServiceCard}>
            <CardContent>
              <Typography variant="h5" className={classes.bCardTitle}>Equipment Rental</Typography>
              <Divider className={classes.bCardDivider}/>
              <Typography variant="body1" component="p">Are you planning a party, still need a box for your birthday or are you a DJ yourself?</Typography>
              <Typography variant="body1" component="p">We will gladly send you a list of our equipment.</Typography>
              <Typography variant="body1" component="p">Send us a non-binding request for the rental of our equipment for your party here!</Typography>
            </CardContent>
            <CardActions>
              <Link to='/contact/' className={classes.bCardButton}><Button variant="contained" color="inherit" id="s2"><Typography variant="body2" color={"#000000"}>Anfragen</Typography></Button></Link>
            </CardActions>
          </Card>
          <Card className={classes.bServiceCard}>
            <CardContent>
              <Typography variant="h5" className={classes.bCardTitle}>Mixing & Mastering</Typography>
              <Divider className={classes.bCardDivider}/>
              <Typography variant="body1" component="p">Do you need support in music productions or are you planning an intro for your next podcast or would you like a compiled mix?</Typography>
              <Typography variant="body1" component="p">Send us a non-binding enquiry here and tell us exactly what you need.</Typography>
            </CardContent>
            <CardActions>
              <Link to='/contact/' className={classes.bCardButton}><Button variant="contained" color="inherit" id="s3"><Typography variant="body2" color={"#000000"}>Anfragen</Typography></Button></Link>
            </CardActions>
          </Card>
        </Box>
      )
      servicesMobile = (
        <Box className={classes.mServiceCards}>
          <Accordion className={classes.mServiceCard}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="djsets-bookings" id="djsets-bookings" >
              <Typography variant="h5" className={classes.mCardTitle}>DJ Sets / Bookings</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" component="p">You need the right music for your night?</Typography>
              <Typography variant="body1" component="p">Send us a non-binding request for a DJ booking for your party here!</Typography>
              <Typography variant="body1" component="p">We can now look back on 10 years of stage experience and want to get the best possible out of the evening for you.</Typography>
              <Divider className={classes.mCardDivider}/>
              <Link to='/contact/' className={classes.mCardButton}><Button variant="contained" color="inherit" id="s1"><Typography variant="body2" color={"#000000"}>Anfragen</Typography></Button></Link>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.mServiceCard}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="verleih" id="verleih" >
              <Typography variant="h5" className={classes.mcardTitle}>Equipment Rental</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" component="p">Are you planning a party, still need a box for your birthday or are you a DJ yourself?</Typography>
              <Typography variant="body1" component="p">We will gladly send you a list of our equipment.</Typography>
              <Typography variant="body1" component="p">Send us a non-binding request for the rental of our equipment for your party here!</Typography>
              <Divider className={classes.mCardDivider}/>
              <Link to='/contact/' className={classes.mCardButton}><Button variant="contained" color="inherit" id="s2"><Typography variant="body2" color={"#000000"}>Anfragen</Typography></Button></Link>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.mServiceCard}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="mix-master" id="mix-master" >
              <Typography variant="h5" className={classes.mcardTitle}>Mixing & Mastering</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" component="p">Do you need support in music productions or are you planning an intro for your next podcast or would you like a compiled mix?</Typography>
              <Typography variant="body1" component="p">Send us a non-binding enquiry here and tell us exactly what you need.</Typography>
              <Divider className={classes.mCardDivider}/>
              <Link to='/contact/' className={classes.mCardButton}><Button variant="contained" color="inherit" id="s3"><Typography variant="body2" color={"#000000"}>Anfragen</Typography></Button></Link>
            </AccordionDetails>
          </Accordion>
        </Box>
      )
    }

    return(
      <div className={classes.root}>
        <BrowserView className={classes.bRoot}>
          <img src={"/img/logo.png"} alt={"Tesflour Logo"} className={classes.bImageSlide} />
          {servicesBrowser}
        </BrowserView>
        <MobileView className={classes.mRoot}>
          <img src={"/img/logo.png"} alt={"Tesflour Logo"} className={classes.mImageSlide} />
          <Typography variant="h3" fontWeight={200}>Services</Typography>
          {servicesMobile}
        </MobileView>
      </div>
    )
  }
}

export default withStyles(style)(ServicesComponent)