import React, { Component } from "react"
import { BrowserView, MobileView } from "react-device-detect"
import axios from "axios";
import { withStyles } from "@mui/styles"

import { Typography, Box, TextField, FormControl, InputLabel, Select, MenuItem, Button, Alert } from "@mui/material"
import { Send, Delete } from "@mui/icons-material"

const style = theme => ({
  bRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  bImageSlide: {
    width: "60%",
    backdropFilter: "blur(10px)",
    padding: theme.spacing(2),
    //animation: `$moveUpwards 1000ms ${theme.transitions.easing.easeInOut}`
  },
  bContactPreText: {
    marginTop: "2vh !important",
    padding: "10px",
    backdropFilter: "blur(20px) brightness(50%)",
    borderRadius: "8px",
    textAlign: "center",
    animation: `$slowShow 800ms ${theme.transitions.easing.easeInOut}`
  },
  bFormBox: {
    marginTop: "1vh !important",
    padding: "1vw 1vh !important",
    width: '50%',
    backdropFilter: "blur(20px) brightness(50%)",
    borderRadius: "8px",
    animation: `$slowShow 800ms ${theme.transitions.easing.easeInOut}`
  },
  bContactForm: {
    display: "flex",
    width: "90%",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bFormTitle: {
    margin: "auto",
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "center",
    borderBottom: "2px solid #ffffff"

  },
  bFormBoxSingle: {
    margin: theme.spacing(1),
  },
  bFormBoxDouble: {
    display: "flex",
    margin: theme.spacing(1),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bTextFieldSingle: {
    margin: "1vw 1vh !important", 
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#ffffff",
  },
  bTextFieldMessage: {
    margin: "1vw 1vh !important",
    overlapY: 'scroll',
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#ffffff",
  },
  bTextfieldDouble: {
    width: "48% !important",
  },
  bFormSelect: {
    width: "48% !important",
  },
  bDatePicker: {
    width: "48% !important",
  },
  bFormInput: {
    color: "#ffffff !important",
  },
  bFormInputLabel: {
    color: "#ffffff80 !important",
  },
  bFormButtonBox: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderTop: "2px solid #ffffff",
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  bFormButton: {
    display: "flex",
    width: "45%",
    paddingLeft: "15%",
    paddingRight: "15%",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  "@keyframes moveUpwards": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    }
  },
  "@keyframes slowShow": {
    "0%": {
      opacity: 0,
      backdropFilter: "blur(0px) brightness(100%)",
      borderRadius: "0px",
    },
    "100%": {
      opacity: 1,
      backdropFilter: "blur(20px) brightness(50%)",
      borderRadius: "8px",
    }
  },
  mRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  mImageSlide: {
    width: "80%",
    backdropFilter: "blur(10px)",
    padding: theme.spacing(2),
    //animation: `$moveUpwards 1000ms ${theme.transitions.easing.easeInOut}`
  },
  mContactPreText: {
    marginTop: "2vh !important",
    padding: "1vh",
    backdropFilter: "blur(20px) brightness(50%)",
    borderRadius: "8px",
    textAlign: "center",
    animation: `$slowShow 800ms ${theme.transitions.easing.easeInOut}`
  },
  mFormBox: {
    marginTop: "1vh !important",
    padding: "1vw 1vh !important",
    width: '100%',
    backdropFilter: "blur(20px) brightness(50%)",
    borderRadius: "8px",
    animation: `$slowShow 800ms ${theme.transitions.easing.easeInOut}`
  },
  mContactForm: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mFormTitle: {
    margin: "auto",
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "center",
    borderBottom: "2px solid #ffffff"

  },
  mFormBoxSingle: {
    margin: theme.spacing(1),
  },
  mFormBoxDouble: {
    display: "flex",
    margin: theme.spacing(1),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mTextFieldSingle: {
    margin: "1vw 1vh !important", 
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#ffffff",
  },
  mTextFieldMessage: {
    width: "100% !important",
    margin: "1vw 1vh !important", 
    overlapY: 'scroll',
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    color: "#ffffff",
  },
  mTextfieldDouble: {
    width: "100% !important",
  },
  mFormSelect: {
    width: "100% !important",
  },
  mDatePicker: {
    width: "100% !important",
  },
  mFormInput: {
    color: "#ffffff !important",
  },
  mFormInputLabel: {
    color: "#ffffff80 !important",
  },
  mFormButtonBox: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderTop: "2px solid #ffffff",
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
  },
  mFormButton: {
    display: "flex",
    width: "45%",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
})

class ContactComponent extends Component {
  constructor(props) {
    super(props)

    this.backendUrl = 'https://api.tesflour.com/api/v2/'

    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      language: props.language,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      type: '',
      date: '',
      message: '',
      sendRequest: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ language: nextProps.language });  
  }

  onChangeFirstName(e) {
    this.setState({
      firstName: e.target.value
    })
  }

  onChangeLastName(e) {
    this.setState({
      lastName: e.target.value
    })
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    })
  }

  onChangePhone(e) {
    this.setState({
      phone: e.target.value
    })
  }

  onChangeType(e) {
    this.setState({
      type: e.target.value
    })
  }

  onChangeDate(e) {
    this.setState({
      date: e.target.value
    })
  }

  onChangeMessage(e) {
    this.setState({
      message: e.target.value
    })
  }

  onClear(e) {
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      type: '',
      date: '',
      message: '',
    });
  }

  onSubmit(e) {
    e.preventDefault()

    const inFirstName = this.state.firstName
    const inLastName = this.state.lastName
    const inEmail = this.state.email
    const inPhone = this.state.phone
    const inDate = this.state.date
    const inMessage = this.state.message
    let inType = ''

    switch(this.state.type) {
      case 'question':
        inType = 'Frage'
        break;
      case 'booking':
        inType = 'Booking'
        break;
      case 'rental':
        inType = 'Verleih'
        break;
      case 'mastering':
        inType = 'Mix & Master'
        break;
      case 'collab':
        inType = 'Collaboration'
        break;
      case 'other':
        inType = 'Anderes'
        break;
      default:
        console.log('ERROR with Type')
    }

    const requestBody = {
      firstName: inFirstName,
      lastName: inLastName,
      email: inEmail,
      phone: inPhone,
      date: inDate,
      type: inType,
      message: inMessage
    }

    axios.post("https://api.tesflour.com/api/v2/contact/request", requestBody)
    .then(apiResult => {
      this.setState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        type: '',
        date: '',
        message: '',
        sendRequest: true
      })
    })
  }

  render() {
    const {classes} = this.props

    let contactBrowser = null
    let contactMobile = null

    let descriptionBanner = null
    let descriptionBannerMobile = null

    if (this.state.language === "de") {
      contactBrowser = (
        <Box className={classes.bFormBox}>
          <form className={classes.bFontactFrom} noValidate autoComplete="off">
            <Typography variant="h4" className={classes.bFormTitle}>Kontakt Formular</Typography>
            <Box className={classes.bFormBoxDouble}>
              <TextField className={classes.bTextfieldDouble} onChange={this.onChangeFirstName} variant="standard" inputProps={{className: classes.bFormInput}} InputLabelProps={{className: classes.bFormInputLabel}} value={this.state.firstName} label="Vorname" id="firstname" fullWidth required />
              <TextField className={classes.bTextfieldDouble} onChange={this.onChangeLastName} variant="standard" inputProps={{className: classes.bFormInput}} InputLabelProps={{className: classes.bFormInputLabel}} value={this.state.lastName} label="Nachname" id="lastname" fullWidth required />
            </Box>
            <Box className={classes.bFormBoxDouble}>
              <TextField className={classes.bTextfieldDouble} onChange={this.onChangeEmail} variant="standard" inputProps={{className: classes.bFormInput}} InputLabelProps={{className: classes.bFormInputLabel}} value={this.state.email} label="Email" id="email" fullWidth required />
              <TextField className={classes.bTextfieldDouble} onChange={this.onChangePhone} variant="standard" inputProps={{className: classes.bFormInput}} InputLabelProps={{className: classes.bFormInputLabel}} value={this.state.phone} label="Telefon" id="phone" fullWidth required />
            </Box>
            <Box className={classes.bFormBoxDouble}>
              <FormControl className={classes.bFormSelect}>
                <InputLabel id="type-select-label" className={classes.bFormInputLabel}>Anliegen</InputLabel>
                <Select labelId="type-select-label" variant="standard" inputProps={{className: classes.bFormInput}} value={this.state.type} onChange={this.onChangeType} id="type" required>
                  <MenuItem value={"question"}>Frage</MenuItem>
                  <MenuItem value={"booking"}>Event Booking</MenuItem>
                  <MenuItem value={"rental"}>Verleih</MenuItem>
                  <MenuItem value={"mastering"}>Mix & Master</MenuItem>
                  <MenuItem value={"collab"}>Collaboration</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl >
              <TextField className={classes.bDatePicker} onChange={this.onChangeDate} value={this.state.date} variant="standard" inputProps={{className: classes.bFormInput}} InputLabelProps={{className: classes.bFormInputLabel}} label="Datum" id="date" fullWidth required />
            </Box>
            <Box className={classes.bFormBoxSingle}>
              <TextField className={classes.bTextFieldMessage} onChange={this.onChangeMessage} value={this.state.message} variant="outlined" inputProps={{className: classes.bFormInput}} InputLabelProps={{className: classes.bFormInputLabel}} label="Nachricht" id="message" multiline rows={5} fullWidth required/>
            </Box>
          </form>
          <Box className={classes.bFormButtonBox}>
            <Button variant="contained" color="inherit" id="clear" onClick={this.onClear} className={classes.bFormButton}><Typography style={{ fontSize: 24, color: "#000000" }}>Löschen</Typography><Delete style={{ fontSize: 24, color: "#000000" }} /></Button>
            <Button variant="contained" color="inherit" id="submit" onClick={this.onSubmit} className={classes.bFormButton}><Typography style={{ fontSize: 24, color: "#000000" }}>Abschicken</Typography><Send style={{ fontSize: 24, color: "#000000" }} /></Button>
          </Box>
        </Box>
      )
      contactMobile = (
        <Box className={classes.mFormBox}>
          <form className={classes.mFontactFrom} noValidate autoComplete="off">
            <Box className={classes.mFormBoxSingle}>
              <TextField className={classes.mTextFieldSingle} onChange={this.onChangeFirstName} variant="standard" inputProps={{className: classes.mFormInput}} InputLabelProps={{className: classes.mFormInputLabel}} value={this.state.firstName} label="Vorname" id="firstname" fullWidth required />
            </Box>
            <Box className={classes.mFormBoxSingle}>
              <TextField className={classes.mTextFieldSingle} onChange={this.onChangeLastName} variant="standard" inputProps={{className: classes.mFormInput}} InputLabelProps={{className: classes.mFormInputLabel}} value={this.state.lastName} label="Nachname" id="lastname" fullWidth required />
            </Box>
            <Box className={classes.mFormBoxSingle}>
              <TextField className={classes.mTextFieldSingle} onChange={this.onChangeEmail} variant="standard" inputProps={{className: classes.mFormInput}} InputLabelProps={{className: classes.mFormInputLabel}} value={this.state.email} label="Email" id="email" fullWidth required />
            </Box>
            <Box className={classes.mFormBoxSingle}>
              <TextField className={classes.mTextFieldSingle} onChange={this.onChangePhone} variant="standard" inputProps={{className: classes.mFormInput}} InputLabelProps={{className: classes.mFormInputLabel}} value={this.state.phone} label="Telefon" id="phone" fullWidth required />
            </Box>
            <Box className={classes.mFormBoxSingle}>
              <FormControl className={classes.mFormSelect}>
                <InputLabel id="type-select-label" className={classes.mFormInputLabel}>Anliegen</InputLabel>
                <Select labelId="type-select-label" variant="standard" inputProps={{className: classes.mFormInput}} value={this.state.type} onChange={this.onChangeType} id="type" required>
                  <MenuItem value={"question"}>Frage</MenuItem>
                  <MenuItem value={"booking"}>Event Booking</MenuItem>
                  <MenuItem value={"rental"}>Verleih</MenuItem>
                  <MenuItem value={"mastering"}>Mix & Master</MenuItem>
                  <MenuItem value={"collab"}>Collaboration</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl >
            </Box>
            <Box className={classes.mFormBoxSingle}>
              <TextField className={classes.mDatePicker} onChange={this.onChangeDate} value={this.state.date} variant="standard" inputProps={{className: classes.mFormInput}} InputLabelProps={{className: classes.mFormInputLabel}} label="Datum" id="date" fullWidth required />
            </Box>
            <Box className={classes.mFormBoxSingle}>
              <TextField className={classes.mTextFieldMessage} onChange={this.onChangeMessage} value={this.state.message} variant="outlined" inputProps={{className: classes.mFormInput}} InputLabelProps={{className: classes.mFormInputLabel}} label="Nachricht" id="message" multiline rows={5} fullWidth required/>
            </Box>
          </form>
          <Box className={classes.mFormButtonBox}>
            <Button variant="contained" color="inherit" id="clear" onClick={this.onClear} className={classes.mFormButton}><Typography style={{ color: "#000000" }}>Löschen</Typography><Delete style={{ color: "#000000" }} /></Button>
            <Button variant="contained" color="inherit" id="submit" onClick={this.onSubmit} className={classes.mFormButton}><Typography style={{ color: "#000000" }}>Abschicken</Typography><Send style={{ color: "#000000" }} /></Button>
          </Box>
        </Box>
      )
    } else if (this.state.language === "en") {
      contactBrowser = (
        <Box className={classes.bFormBox}>
          <form className={classes.bFontactFrom} noValidate autoComplete="off">
            <Typography variant="h4" className={classes.bFormTitle}>Contact Form</Typography>
            <Box className={classes.bFormBoxDouble}>
              <TextField className={classes.bTextfieldDouble} onChange={this.onChangeFirstName} variant="standard" inputProps={{className: classes.bFormInput}} InputLabelProps={{className: classes.bFormInputLabel}} value={this.state.firstName} label="First name" id="firstname" fullWidth required />
              <TextField className={classes.bTextfieldDouble} onChange={this.onChangeLastName} variant="standard" inputProps={{className: classes.bFormInput}} InputLabelProps={{className: classes.bFormInputLabel}} value={this.state.lastName} label="Last name" id="lastname" fullWidth required />
            </Box>
            <Box className={classes.bFormBoxDouble}>
              <TextField className={classes.bTextfieldDouble} onChange={this.onChangeEmail} variant="standard" inputProps={{className: classes.bFormInput}} InputLabelProps={{className: classes.bFormInputLabel}} value={this.state.email} label="Email" id="email" fullWidth required />
              <TextField className={classes.bTextfieldDouble} onChange={this.onChangePhone} variant="standard" inputProps={{className: classes.bFormInput}} InputLabelProps={{className: classes.bFormInputLabel}} value={this.state.phone} label="Phone" id="phone" fullWidth required />
            </Box>
            <Box className={classes.bFormBoxDouble}>
              <FormControl className={classes.bFormSelect}>
                <InputLabel id="type-select-label" className={classes.bFormInputLabel}>Request</InputLabel>
                <Select labelId="type-select-label" variant="standard" inputProps={{className: classes.bFormInput}} value={this.state.type} onChange={this.onChangeType} id="type" required>
                  <MenuItem value={"question"}>Question</MenuItem>
                  <MenuItem value={"booking"}>Event Booking</MenuItem>
                  <MenuItem value={"rental"}>Rental</MenuItem>
                  <MenuItem value={"mastering"}>Mix & Master</MenuItem>
                  <MenuItem value={"collab"}>Collaboration</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl >
              <TextField className={classes.bDatePicker} onChange={this.onChangeDate} value={this.state.date} variant="standard" inputProps={{className: classes.bFormInput}} InputLabelProps={{className: classes.bFormInputLabel}} label="Date" id="date" fullWidth required />
            </Box>
            <Box className={classes.bFormBoxSingle}>
              <TextField className={classes.bTextFieldMessage} onChange={this.onChangeMessage} value={this.state.message} variant="outlined" inputProps={{className: classes.bFormInput}} InputLabelProps={{className: classes.bFormInputLabel}} label="Message" id="message" multiline rows={5} fullWidth required/>
            </Box>
          </form>
          <Box className={classes.bFormButtonBox}>
            <Button variant="contained" color="inherit" id="clear" onClick={this.onClear} className={classes.bFormButton}><Typography style={{ fontSize: 24, color: "#000000" }}>Clear</Typography><Delete style={{ fontSize: 24, color: "#000000" }} /></Button>
            <Button variant="contained" color="inherit" id="submit" onClick={this.onSubmit} className={classes.bFormButton}><Typography style={{ fontSize: 24, color: "#000000" }}>Send</Typography><Send style={{ fontSize: 24, color: "#000000" }} /></Button>
          </Box>
        </Box>
      )
      contactMobile = (
        <Box className={classes.mFormBox}>
          <form className={classes.mFontactFrom} noValidate autoComplete="off">
            <Box className={classes.mFormBoxSingle}>
              <TextField className={classes.mTextFieldSingle} onChange={this.onChangeFirstName} variant="standard" inputProps={{className: classes.mFormInput}} InputLabelProps={{className: classes.mFormInputLabel}} value={this.state.firstName} label="First name" id="firstname" fullWidth required />
            </Box>
            <Box className={classes.mFormBoxSingle}>
              <TextField className={classes.mTextFieldSingle} onChange={this.onChangeLastName} variant="standard" inputProps={{className: classes.mFormInput}} InputLabelProps={{className: classes.mFormInputLabel}} value={this.state.lastName} label="Last name" id="lastname" fullWidth required />
            </Box>
            <Box className={classes.mFormBoxSingle}>
              <TextField className={classes.mTextFieldSingle} onChange={this.onChangeEmail} variant="standard" inputProps={{className: classes.mFormInput}} InputLabelProps={{className: classes.mFormInputLabel}} value={this.state.email} label="Email" id="email" fullWidth required />
            </Box>
            <Box className={classes.mFormBoxSingle}>
              <TextField className={classes.mTextFieldSingle} onChange={this.onChangePhone} variant="standard" inputProps={{className: classes.mFormInput}} InputLabelProps={{className: classes.mFormInputLabel}} value={this.state.phone} label="Phone" id="phone" fullWidth required />
            </Box>
            <Box className={classes.mFormBoxSingle}>
              <FormControl className={classes.mFormSelect}>
                <InputLabel id="type-select-label" className={classes.mFormInputLabel}>Request</InputLabel>
                <Select labelId="type-select-label" variant="standard" inputProps={{className: classes.mFormInput}} value={this.state.type} onChange={this.onChangeType} id="type" required>
                  <MenuItem value={"question"}>Question</MenuItem>
                  <MenuItem value={"booking"}>Event Booking</MenuItem>
                  <MenuItem value={"rental"}>Rental</MenuItem>
                  <MenuItem value={"mastering"}>Mix & Master</MenuItem>
                  <MenuItem value={"collab"}>Collaboration</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl >
            </Box>
            <Box className={classes.mFormBoxSingle}>
              <TextField className={classes.mDatePicker} onChange={this.onChangeDate} value={this.state.date} variant="standard" inputProps={{className: classes.mFormInput}} InputLabelProps={{className: classes.mFormInputLabel}} label="Date" id="date" fullWidth required />
            </Box>
            <Box className={classes.mFormBoxSingle}>
              <TextField className={classes.mTextFieldMessage} onChange={this.onChangeMessage} value={this.state.message} variant="outlined" inputProps={{className: classes.mFormInput}} InputLabelProps={{className: classes.mFormInputLabel}} label="Message" id="message" multiline rows={5} fullWidth required/>
            </Box>
          </form>
          <Box className={classes.mFormButtonBox}>
            <Button variant="contained" color="inherit" id="clear" onClick={this.onClear} className={classes.mFormButton}><Typography style={{ color: "#000000" }}>Clear</Typography><Delete style={{ color: "#000000" }} /></Button>
            <Button variant="contained" color="inherit" id="submit" onClick={this.onSubmit} className={classes.mFormButton}><Typography style={{ color: "#000000" }}>Send</Typography><Send style={{ color: "#000000" }} /></Button>
          </Box>
        </Box>
      )
    }

    if (this.state.language === "de") {
      if(this.state.sendRequest) {
        descriptionBanner = (
          <Alert severity="success" className={classes.bContactPreText}>Deine Anfrage wurde an uns geschickt - Wir werden uns sobald möglich bei dir melden</Alert>
        )
      } else {
        descriptionBanner = (
          <Typography variant="body1" className={classes.bContactPreText}>
            Du willst uns kontaktieren?<br/>Bitte fülle das Kontakt-Formular aus und wir werden uns sobald möglich bei dir melden.
          </Typography>
        )
      }
    } else if (this.state.language === "en") {
      if(this.state.sendRequest) {
        descriptionBanner = (
          <Alert severity="success" className={classes.bContactPreText}>Your request has been sent to us - we will get back to you as soon as possible.</Alert>
        )
      } else {
        descriptionBanner = (
          <Typography variant="body1" className={classes.bContactPreText}>
            You want to contact us?<br/>Please fill out the contact form and we will get back to you as soon as possible.
          </Typography>
        )
      }
    }

    if (this.state.language === "de") {
      if(this.state.sendRequest) {
        descriptionBannerMobile = (
          <Alert severity="success" className={classes.mContactPreText}>Deine Anfrage wurde an uns geschickt - Wir werden uns sobald möglich bei dir melden</Alert>
        )
      } else {
        descriptionBannerMobile = (
          <Typography variant="body1" className={classes.mContactPreText}>
            Du willst uns kontaktieren?<br/>Bitte fülle das Kontakt-Formular aus und wir werden uns sobald möglich bei dir melden.
          </Typography>
        )
      }
    } else if (this.state.language === "en") {
      if(this.state.sendRequest) {
        descriptionBannerMobile = (
          <Alert severity="success" className={classes.mContactPreText}>Your request has been sent to us - we will get back to you as soon as possible.</Alert>
        )
      } else {
        descriptionBannerMobile = (
          <Typography variant="body1" className={classes.mContactPreText}>
            You want to contact us?<br/>Please fill out the contact form and we will get back to you as soon as possible.
          </Typography>
        )
      }
    }

    return(
      <div>
        <BrowserView className={classes.bRoot}>
          <img src={"/img/logo.png"} alt={"Tesflour Logo"} className={classes.bImageSlide} />
          {descriptionBanner}
          {contactBrowser}
        </BrowserView>
        <MobileView className={classes.mRoot}>
          <img src={"/img/logo.png"} alt={"Tesflour Logo"} className={classes.mImageSlide} />
          <Typography variant="h3" fontWeight={200}>Contact</Typography>
          {descriptionBannerMobile}
          {contactMobile}
        </MobileView>  
      </div>
    )
  }
}

export default withStyles(style)(ContactComponent)