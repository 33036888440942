import React, { Component } from "react"
import { BrowserView, MobileView } from "react-device-detect"
import { withStyles } from "@mui/styles"

import { Typography, Box, Container, Skeleton, Button } from "@mui/material"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSoundcloud, faSpotify, faYoutube } from '@fortawesome/free-brands-svg-icons'

const style = theme => ({
  bRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  bImageSlide: {
    width :"60%",
    backdropFilter: "blur(10px)",
    padding: theme.spacing(2),
    //animation: `$moveUpwards 200ms ${theme.transitions.easing.easeInOut}`
  },
  bPlatformsBox: {
    display: "flex",
    marginTop: theme.spacing(2),
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  bPlatformBox: {
    display: "flex",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    backdropFilter: "blur(20px) brightness(50%)",
    borderRadius: "8px",
    animation: `$slowShow 800ms ${theme.transitions.easing.easeInOut}`
  },
  bFrameContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  bSocialLinks: {
    width: "250px",
    height: "100%",
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    border: "2px solid #ffffff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    backdropFilter: "blur(20px) brightness(50%)",
    borderRadius: "8px",
    animation: `$slowShow 800ms ${theme.transitions.easing.easeInOut}`
  },
  bSocialTitle: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    borderBottom: "1px solid #ffffff40", 
  },
  bSocialButton: {
    minWidth: "200px !important",
    marginTop: "2vh !important",
    marginBottom: "2vh !important",
  },
  "@keyframes moveUpwards": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    }
  },
  "@keyframes slowShow": {
    "0%": {
      opacity: 0,
      backdropFilter: "blur(0px) brightness(100%)",
      borderRadius: "0px",
    },
    "100%": {
      opacity: 1,
      backdropFilter: "blur(20px) brightness(50%)",
      borderRadius: "8px",
    }
  },
  mRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  mImageSlide: {
    width :"80%",
    backdropFilter: "blur(10px)",
    padding: theme.spacing(2),
    //animation: `$moveUpwards 200ms ${theme.transitions.easing.easeInOut}`
  },
  mPlatformBox: {
    display: "flex",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    backdropFilter: "blur(20px) brightness(50%)",
    borderRadius: "8px",
    animation: `$slowShow 800ms ${theme.transitions.easing.easeInOut}`
  },
  mFrameContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  mSocialLinks: {
    width: "90vw",
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    border: "2px solid #ffffff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backdropFilter: "blur(10px)",
    borderRadius: "8px",
    animation: `$slowShow 800ms ${theme.transitions.easing.easeInOut}`
  },
  mSocialButton: {
    minWidth: "20vw !important",
    marginLeft: "1vh !important",
    marginRight: "1vh !important",
  },
  mFontAwesomeIcon: {
    fontSize: "24px"
  }
})

class MusicComponent extends Component {
  constructor(props) {
    super(props)

    this.onClickYoutube = this.onClickYoutube.bind(this)
    this.onClickSoundcloud = this.onClickSoundcloud.bind(this)
    this.onClickSpotify = this.onClickSpotify.bind(this)

    this.state = {
      fullLoad: false
    }

  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ fullLoad: true });
    }, 1000)
  }

  onClickYoutube() {
    window.open('https://www.youtube.com/channel/UCfwerKV0idxHa0kLvAwjqYA', '_blank', 'noreferrer');
  }

  onClickSoundcloud() {
    window.open('https://soundcloud.com/tesflour', '_blank', 'noreferrer');
  }

  onClickSpotify() {
    window.open('https://open.spotify.com/artist/0AFxxSt1zKKGGz1gveWaAN', '_blank', 'noreferrer');
  }

  render() {
    const {classes} = this.props;

    const sc1 = (
      <Container className={classes.bFrameContainer}>
        <iframe width="250" height="200" scrolling="no" frameborder="no" allow="autoplay" title="Cuerage X Tesflour - Red Light Green Light" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1158261775&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style={{fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: 100}}><a href="https://soundcloud.com/tesflour" title="Cuerage X Tesflour - Red Light Green Light" target="_blank" rel="noreferrer" style={{color: "#cccccc", textDecoration: "none"}}>Tesflour</a> · <a href="https://soundcloud.com/cuerage/cuerage-x-tesflour-red-light-green-light" title="Cuerage X Tesflour - Red Light Green Light" target="_blank" rel="noreferrer" style={{color: "#cccccc", textDecoration: "none"}}>Cuerage X Tesflour - Red Light Green Light</a></div>
      </Container>
    )
    const sc2 = (
      <Container className={classes.bFrameContainer}>
        <iframe width="250" height="200" scrolling="no" frameborder="no" allow="autoplay" title="Tesflour - Red Lies" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/416065953&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style={{fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: 100}}><a href="https://soundcloud.com/tesflour" title="Tesflour - Red Lies" target="_blank" rel="noreferrer" style={{color: "#cccccc", textDecoration: "none"}}>Tesflour</a> · <a href="https://soundcloud.com/tesflour/red-lies" title="Red Lies" target="_blank" rel="noreferrer" style={{color: "#cccccc", textDecoration: "none"}}>Red Lies</a></div>
      </Container>
    )
    const sc3 = (
      <Container className={classes.bFrameContainer}>
        <iframe width="250" height="200" scrolling="no" frameborder="no" allow="autoplay" title="Cuerage X Tesflour - Runnin" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/387449831&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style={{fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: 100}}><a href="https://soundcloud.com/tesflour" title="Tesflour X Cuerage - Runnin" target="_blank" rel="noreferrer" style={{color: "#cccccc", textDecoration: "none"}}>Tesflour</a> · <a href="https://soundcloud.com/tesflour/runnin" title="Cuerage X Tesflour - Runnin" target="_blank" rel="noreferrer" style={{color: "#cccccc", textDecoration: "none"}}>Cuerage X Tesflour - Runnin</a></div>
      </Container>
    )
    const sc4 = (
      <Container className={classes.bFrameContainer}>
        <iframe width="250" height="200" scrolling="no" frameborder="no" allow="autoplay" title="Tesflour - Skyline" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/174093036&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style={{fontSize: "10px", color: "#cccccc", lineBreak: "anywhere", wordBreak: "normal", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", fontFamily: "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif", fontWeight: 100}}><a href="https://soundcloud.com/tesflour" title="Tesflour - Skyline" target="_blank" rel="noreferrer" style={{color: "#cccccc", textDecoration: "none"}}>Tesflour</a> · <a href="https://soundcloud.com/tesflour/tesflour-skyline" title="Tesflour - Skyline" target="_blank" rel="noreferrer" style={{color: "#cccccc", textDecoration: "none"}}>Tesflour - Skyline</a></div>
      </Container>
    )
    const yt1 = (
      <Container className={classes.bFrameContainer}>
        <iframe width="250" height="200" src="https://www.youtube-nocookie.com/embed/7X9QpGx4hwU" title="Turn All The Lights On - Opening Mix" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </Container>
    )
    const yt2 = (
      <Container className={classes.bFrameContainer}>
        <iframe width="250" height="200" src="https://www.youtube-nocookie.com/embed/videoseries?list=PL9_yen6sFca_HCfW3OWSWyKPZn1wpBp4i" title="Dance As One - Tesflour Playlist" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </Container>
    )

    return(
      <div>
        <BrowserView className={classes.bRoot}>
          <img src={"/img/logo.png"} alt={"Tesflour Logo"} className={classes.bImageSlide} />
          <Box className={classes.bPlatformsBox}>
            <Box className={classes.bPlatformBox}>
              {this.state.fullLoad ? (
                sc1
              ) : (
                <Skeleton variant="rectangular" width={250} height={200} animation="wave" className={classes.bFrameContainer} />
              )}
              {this.state.fullLoad ? (
                sc2
              ) : (
                <Skeleton variant="rectangular" width={250} height={200} animation="wave" className={classes.bFrameContainer} />
              )}
            </Box>
            <Box className={classes.bPlatformBox}>
              {this.state.fullLoad ? (
                yt1
              ) : (
                <Skeleton variant="rectangular" width={250} height={200} animation="wave" className={classes.bFrameContainer} />
              )}
              {this.state.fullLoad ? (
                yt2
              ) : (
                <Skeleton variant="rectangular" width={250} height={200} animation="wave" className={classes.bFrameContainer} />
              )}
            </Box>
            <Box className={classes.bPlatformBox}>
              {this.state.fullLoad ? (
                sc3
              ) : (
                <Skeleton variant="rectangular" width={250} height={200} animation="wave" className={classes.bFrameContainer} />
              )}
              {this.state.fullLoad ? (
                sc4
              ) : (
                <Skeleton variant="rectangular" width={250} height={200} animation="wave" className={classes.bFrameContainer} />
              )}
            </Box>
            <Box className={classes.bSocialLinks}>
              <Typography variant="h5" className={classes.bSocialTitle}>Platform Links</Typography>
              <Button variant="contained" color="error" className={classes.bSocialButton} onClick={this.onClickYoutube} id="youtubebtn"><Typography variant="h5">Youtube</Typography></Button>
              <Button variant="contained" color="warning" className={classes.bSocialButton} onClick={this.onClickSoundcloud} id="soundcloudbtn"><Typography variant="h5">Soundcloud</Typography></Button>
              <Button variant="contained" color="success" className={classes.bSocialButton} onClick={this.onClickSpotify} id="spotifybtn"><Typography variant="h5">Spotify</Typography></Button>
            </Box>
          </Box>
        </BrowserView>
        <MobileView className={classes.mRoot}>
          <img src={"/img/logo.png"} alt={"Tesflour Logo"} className={classes.mImageSlide} />
          <Typography variant="h3" fontWeight={200}>Music</Typography>
          <Box className={classes.mSocialLinks}>
            <Button variant="contained" color="error" className={classes.mSocialButton} onClick={this.onClickYoutube} id="youtubebtn"><FontAwesomeIcon icon={faYoutube}  className={classes.mFontAwesomeIcon} /></Button>
            <Button variant="contained" color="warning" className={classes.mSocialButton} onClick={this.onClickSoundcloud} id="soundcloudbtn"><FontAwesomeIcon icon={faSoundcloud} className={classes.mFontAwesomeIcon} /></Button>
            <Button variant="contained" color="success" className={classes.mSocialButton} onClick={this.onClickSpotify} id="spotifybtn"><FontAwesomeIcon icon={faSpotify} className={classes.mFontAwesomeIcon} /></Button>
          </Box>
          <Box className={classes.bPlatformBox}>
            {this.state.fullLoad ? (
              sc1
            ) : (
              <Skeleton variant="rectangular" width={250} height={200} animation="wave" className={classes.bFrameContainer} />
            )}
            {this.state.fullLoad ? (
              sc2
            ) : (
              <Skeleton variant="rectangular" width={250} height={200} animation="wave" className={classes.bFrameContainer} />
            )}
            {this.state.fullLoad ? (
              yt1
            ) : (
              <Skeleton variant="rectangular" width={250} height={200} animation="wave" className={classes.bFrameContainer} />
            )}
            {this.state.fullLoad ? (
              yt2
            ) : (
              <Skeleton variant="rectangular" width={250} height={200} animation="wave" className={classes.bFrameContainer} />
            )}
            {this.state.fullLoad ? (
              sc3
            ) : (
              <Skeleton variant="rectangular" width={250} height={200} animation="wave" className={classes.bFrameContainer} />
            )}
            {this.state.fullLoad ? (
              sc4
            ) : (
              <Skeleton variant="rectangular" width={250} height={200} animation="wave" className={classes.bFrameContainer} />
            )}
          </Box>
        </MobileView>
      </div>
    )
  }
}

export default withStyles(style)(MusicComponent)