import React, {Component, Fragment} from "react";
import { BrowserView, MobileView } from 'react-device-detect';
import { Route, Link, Routes } from "react-router-dom";

import { withStyles } from "@mui/styles"
import { Typography, Box, AppBar, Toolbar, Button, SwipeableDrawer, List, ListItem, ListItemText, Menu, MenuItem } from "@mui/material";
import {Menu as MenuIcon} from "@mui/icons-material"

import HomeComponent from "./home"
import MusicComponent from "./music"
import ContactComponent from "./contact"
import ServicesComponent from "./services"
import AboutComponent from "./about"
import LegalComponent from "./legal"

const style = theme => ({
  root: {
    backgroundColor: '#1e1e1e',
    color: "#ffffff",
  },
  bBackground: {
    display: 'grid',
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(/img/background.jpg)`,
    backgroundSize: '100%',
    backgroundPosition: 'center 40%',
    backgroundRepeat: 'no-repeat',
    gridTemplateColumns: "100%",
    gridTemplateRows: "60px auto",
    alignContent: "stretch",
    alignItems: "stretch",
    justifyContent: "stretch",
    justifyItems: "stretch",
  },
  bNavbar: {
    width: "100vw",
  },
  bToolbar: {
    //display: "flex",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "100vw",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  bNavbarButtonBox: {
    margin: "auto",
    animation: `$slideTop 1000ms ${theme.transitions.easing.easeInOut}`,
    //order: 2,
  },
  bNavbarButton: {
    minWidth: "50px",
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    color: "#ffffff",
    textDecoration: "none",
  },
  bLanguageButton: {
    minWidth: "50px",
    color: "#ffffff",
    textDecoration: "none",
  },
  bTextDisplay: {
    color: "#ffffff",
  },
  bContent: {
    padding: theme.spacing(2),
    fontWeight: 200,
    color: "#ffffff",
    //animation: `$slowShow 5000ms ${theme.transitions.easing.easeInOut}`,
  },
  "@keyframes slideTop": {
    "0%": {
      opacity: 0,
      transform: "translateY(-30%)"
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)"
    }
  },
  mBackground: {
    display: 'grid',
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(/img/background.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundAttachment: "local",
    backgroundRepeat: 'no-repeat',
    gridTemplateColumns: "100%",
    gridTemplateRows: "60px auto",
    alignContent: "stretch",
    alignItems: "stretch",
    justifyContent: "stretch",
    justifyItems: "stretch",
    overflowY: "auto",
  },
  mDrawer: {
    background: "transparent"
  },
  mNavbarButton: {
    width: "100%",
    color: "#ffffff",
    textDecoration: "none",
  },
  mNavbar: {
    width: "100vw",
  },
  mTextDisplay: {
    color: "#ffffff",
  },
  mFragment: {
    display: "flex",
    justifyContent: "space-between" 
  },
  mListItem: {
    width: "100%",
  },
  mListLogo: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: "solid 2px #ffffff40"
  },
  mImage: {
    width: "100%",
    paddingBottom: theme.spacing(2),
  },
  mListItemText: {
    textAlign: "right"
  },
  mListButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  mMenuButton: {
    display: "flex !important",
    float: "right",
    width: "40%",
    marginRight: "0px",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  mMenuButtonIcon: {
    color: "#ffffff",
    marginRight: "10px"
  },
  mMenuButtonText: {
    color: "#ffffff",
  },
  mContent: {
    padding: theme.spacing(2),
    fontWeight: 200,
    color: "#ffffff",
    //animation: `$slowShow 5000ms ${theme.transitions.easing.easeInOut}`,
  },
  mLanguageButton: {
    marginRight: 'auto',
    color: "#ffffff",
    textDecoration: "none",
  }
})

class NavbarComponent extends Component {
  constructor(props) {
    super(props)
    
    this.changeLangDe = this.changeLangDe.bind(this)
    this.changeLangEn = this.changeLangEn.bind(this)
    this.handleLangClick = this.handleLangClick.bind(this)
    this.closeLangMenu = this.closeLangMenu.bind(this)
    this.toggleDrawer = this.toggleDrawer.bind(this)

    this.state = {
      language: "en",
      anchorEl: null,
      showLangMenu: false,
      right: false,
      showDrawer: false,
    }
  }

  changeLangDe = () => {
    this.setState({
      language: "de",
      anchorEl: null,
      showLangMenu: false
    });
  }

  changeLangEn = () => {
    this.setState({
      language: "en",
      anchorEl: null,
      showLangMenu: false
    });
  }

  handleLangClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      showLangMenu: true
    });
  }

  closeLangMenu = () => {
    this.setState({
      anchorEl: null,
      showLangMenu: false
    });
  }

  toggleDrawer = (anchor, open) => (event) => {
    if(event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({...this.state, [anchor]: open})
  }

  render() {
    const {classes} = this.props;

    const list = (anchor) => (
      <Box sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }} role="presentation" onClick={this.toggleDrawer(anchor, false)} onKeyDown={this.toggleDrawer(anchor, false)}>
        <List className={classes.mDrawer}>
          <ListItem key="icon" className={classes.mListLogo}>
              <img src={"/img/logo.png"} alt={"Tesflour Logo"} className={classes.mImage} />
          </ListItem>
          <ListItem button key="home" className={classes.mListItem}>
            <ListItemText className={classes.mListItemText}>
              <Link to='/' className={classes.mNavbarButton}><Typography variant="h4" fontWeight={200} className={classes.bTextDisplay}>Home</Typography></Link>
            </ListItemText>
          </ListItem>
          <ListItem button key="music">
            <ListItemText className={classes.mListItemText}>
              <Link to='/music/' className={classes.mNavbarButton}><Typography variant="h4" fontWeight={200} className={classes.bTextDisplay}>Music</Typography></Link>
            </ListItemText>
          </ListItem>
          <ListItem button key="contact">
            <ListItemText className={classes.mListItemText}>
              <Link to='/contact/' className={classes.mNavbarButton}><Typography variant="h4" fontWeight={200} className={classes.bTextDisplay}>Contact</Typography></Link>
            </ListItemText>
          </ListItem>
          <ListItem button key="services">
            <ListItemText className={classes.mListItemText}>
              <Link to='/services/' className={classes.mNavbarButton}><Typography variant="h4" fontWeight={200} className={classes.bTextDisplay}>Services</Typography></Link>
            </ListItemText>
          </ListItem>
          <ListItem button key="about">
            <ListItemText className={classes.mListItemText}>
              <Link to='/about/' className={classes.mNavbarButton}><Typography variant="h4" fontWeight={200} className={classes.bTextDisplay}>About Us</Typography></Link>
            </ListItemText>
          </ListItem>
          <ListItem button key="legal">
            <ListItemText className={classes.mListItemText}>
              <Link to='/legal/' className={classes.mNavbarButton}><Typography variant="h4" fontWeight={200} className={classes.bTextDisplay}>Legal</Typography></Link>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
    )

    return(
      <div className={classes.root}>
        <BrowserView>
          <Box className={classes.bBackground}>
            <AppBar position="static" color={"transparent"} className={classes.bNavbar}>
              <Toolbar className={classes.bToolbar}>
                <Box className={classes.bNavbarButtonBox}>
                  <Link to='/' className={classes.bNavbarButton}><Button key="home" id="home"><Typography variant="h4" fontWeight={200} className={classes.bTextDisplay}>Home</Typography></Button></Link>
                  <Link to='/music/' className={classes.bNavbarButton}><Button key="music" id="music"><Typography variant="h4" fontWeight={200} className={classes.bTextDisplay}>Music</Typography></Button></Link>
                  <Link to='/contact/' className={classes.bNavbarButton}><Button key="contact" id="contact"><Typography variant="h4" fontWeight={200} className={classes.bTextDisplay}>Contact</Typography></Button></Link>
                  <Link to='/services/' className={classes.bNavbarButton}><Button key="services" id="services"><Typography variant="h4" fontWeight={200} className={classes.bTextDisplay}>Services</Typography></Button></Link>
                  <Link to='/about/' className={classes.bNavbarButton}><Button key="aboutus" id="aboutus"><Typography variant="h4" fontWeight={200} className={classes.bTextDisplay}>About Us</Typography></Button></Link>
                  <Link to='/legal/' className={classes.bNavbarButton} ><Button key="legal" id="legal"><Typography variant="h4" fontWeight={200} className={classes.bTextDisplay}>Legal</Typography></Button></Link>
                </Box>
                <Button className={classes.bLanguageButton} key="language" id="language" aria-haspopup="true" aria-controls={this.state.showLangMenu ? 'lang-menu' : undefined} aria-expanded={this.state.showLangMenu ? 'true' : 'false'} onClick={this.handleLangClick}><Typography variant="body1" fontWeight={400} className={classes.bTextDisplay}>DE/EN</Typography></Button>
                <Menu id="lang-menu" anchorEl={this.state.anchorEl} open={this.state.showLangMenu} onClose={this.closeLangMenu} MenuListProps={{'aria-labelledby': 'lang-button', }}>
                  <MenuItem onClick={this.changeLangDe}>Deutsch</MenuItem>
                  <MenuItem onClick={this.changeLangEn}>English</MenuItem>
                </Menu>
              </Toolbar>
            </AppBar>
            <main className={classes.bContent}>
              <Routes>
                <Route path="/" exact element={<HomeComponent />} />
                <Route path="/music/" element={<MusicComponent />} />
                <Route path="/contact/"  element={<ContactComponent language={this.state.language} />} />
                <Route path="/services/" element={<ServicesComponent language={this.state.language} />} />
                <Route path="/about/" element={<AboutComponent language={this.state.language} />} />
                <Route path="/legal/" element={<LegalComponent language={this.state.language} />} />
              </Routes>
            </main>
          </Box>
        </BrowserView>
        <MobileView>
          <Box className={classes.mBackground}>
            <Box className={classes.mFragment}>
              <Fragment key={'right'}>
                <Button className={classes.mMenuButton} onClick={this.toggleDrawer('right', true)}><MenuIcon className={classes.mMenuButtonIcon} /><Typography variant="h5" className={classes.mMenuButtonText}>Menu</Typography></Button>
                <SwipeableDrawer anchor={'right'} open={this.state['right']} onClose={this.toggleDrawer('right', false)} onOpen={this.toggleDrawer('right', true)} PaperProps={{sx: {background: "#ffffff10"}}} BackdropProps={{sx: {backdropFilter: "blur(10px)"}}}>
                  {list('right')}
                </SwipeableDrawer>
              </Fragment>
              <Button className={classes.mLanguageButton} key="language" id="language" aria-haspopup="true" aria-controls={this.state.showLangMenu ? 'lang-menu' : undefined} aria-expanded={this.state.showLangMenu ? 'true' : 'false'} onClick={this.handleLangClick}><Typography variant="body1" fontWeight={400} className={classes.bTextDisplay}>DE/EN</Typography></Button>
              <Menu id="lang-menu" anchorEl={this.state.anchorEl} open={this.state.showLangMenu} onClose={this.closeLangMenu} MenuListProps={{'aria-labelledby': 'lang-button', }}>
                <MenuItem onClick={this.changeLangDe}>Deutsch</MenuItem>
                <MenuItem onClick={this.changeLangEn}>English</MenuItem>
              </Menu>
            </Box>
            <main className={classes.mContent}>
              <Routes>
                <Route path="/" exact element={<HomeComponent />} />
                <Route path="/music/" element={<MusicComponent />} />
                <Route path="/contact/"  element={<ContactComponent language={this.state.language} />} />
                <Route path="/services/" element={<ServicesComponent language={this.state.language} />} />
                <Route path="/about/" element={<AboutComponent language={this.state.language} />} />
                <Route path="/legal/" element={<LegalComponent language={this.state.language} />} />
              </Routes>
            </main>
          </Box>
        </MobileView>
      </div>
    )
  }
}

export default withStyles(style)(NavbarComponent);