import React, { Component } from "react";

import { withStyles } from "@mui/styles";
import { Typography, Box } from "@mui/material"
import { BrowserView, MobileView } from "react-device-detect";

const style = theme => ({
  root: {
    height: "100% !important"
  },
  bRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  bImageSlide: {
    width :"60%",
    backdropFilter: "blur(10px)",
    padding: theme.spacing(2),
    //animation: `$moveUpwards 200ms ${theme.transitions.easing.easeInOut}`
  },
  bAboutText: {
    marginTop: theme.spacing(12),
    padding: theme.spacing(2),
    backdropFilter: "blur(20px) brightness(50%)",
    animation: `$slowShow 800ms ${theme.transitions.easing.easeInOut}`
  },
  "@keyframes moveUpwards": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    }
  },
  "@keyframes slowShow": {
    "0%": {
      opacity: 0,
      backdropFilter: "blur(0px) brightness(100%)",
      borderRadius: "0px",
    },
    "100%": {
      opacity: 1,
      backdropFilter: "blur(20px) brightness(50%)",
      borderRadius: "8px",
    }
  },
  mRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  mImageSlide: {
    width :"80%",
    backdropFilter: "blur(10px)",
    padding: theme.spacing(2),
    //animation: `$moveUpwards 200ms ${theme.transitions.easing.easeInOut}`
  },
  mAboutText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    backdropFilter: "blur(20px) brightness(50%)",
    borderRadius: "8px",
    animation: `$slowShow 800ms ${theme.transitions.easing.easeInOut}`
  },
})

class AboutComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      language: props.language
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ language: nextProps.language });  
  }

  render() {
    const {classes} = this.props;

    let aboutBrowser = null
    let aboutMobile = null
    
    if (this.state.language === "de") {
      aboutBrowser = (
        <Box className={classes.bAboutText}>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Unsere Geschichte begann im Jahr 2014. Schon davor haben wir schon erste Erfahrungen in der Musikwelt sammeln können und legten damals im Mai fest, künftig als Trio mit dem Namen „Tesflour“ aufzutreten. Immer wieder erreichten uns Fragen über die Herkunft des Namens. Eine genaue Antwort gibt es darauf nicht.   
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            In unseren Sets finden sich sehr unterschiedliche Genres und Subgenres wieder. Vor allem Deep/Tech/ Future, aber auch UK Bassline, UKG oder Drum and Bass spielen dabei eine prägende Rolle. Neben unseren Auftritten haben wir bereits auch einige Musikproduktionen veröffentlicht.   
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Die Jahre haben gezeigt, dass wir als Trio Jahr für Jahr mehr zusammengewachsen sind. Wir beschäftigen uns laufend mit immer neuen Musikgenres und sind zu Dritt offener für viele verschiedene Musikrichtungen. Jeder von uns hat seinen persönlichen Input, den er für Tesflour gibt und wir damit als DJ-Gruppe perfekt harmonieren. Die Energie, die wir tagtäglich dafür investieren, wollen wir bei jeder Gelegenheit an unser Publikum, an EUCH übertragen! Das zeichnet uns aus, dafür stehen wir!   
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Wir sind noch lange nicht am Ende. Freut euch also auf das, was kommt!
          </Typography>
          <Typography variant="h5" marginTop={"4vh"} marginBottom={"2vh"}>
            Tesflour - Jan, Lorenz, Lukas
          </Typography>
        </Box>
      )
      aboutMobile = (
        <Box className={classes.mAboutText}>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Unsere Geschichte begann im Jahr 2014. Schon davor haben wir schon erste Erfahrungen in der Musikwelt sammeln können und legten damals im Mai fest, künftig als Trio mit dem Namen „Tesflour“ aufzutreten. Immer wieder erreichten uns Fragen über die Herkunft des Namens. Eine genaue Antwort gibt es darauf nicht.   
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            In unseren Sets finden sich sehr unterschiedliche Genres und Subgenres wieder. Vor allem Deep/Tech/ Future, aber auch UK Bassline, UKG oder Drum and Bass spielen dabei eine prägende Rolle. Neben unseren Auftritten haben wir bereits auch einige Musikproduktionen veröffentlicht.   
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Die Jahre haben gezeigt, dass wir als Trio Jahr für Jahr mehr zusammengewachsen sind. Wir beschäftigen uns laufend mit immer neuen Musikgenres und sind zu Dritt  offener für viele verschiedene Musikrichtungen. Jeder von uns hat seinen persönlichen Input, den er für Tesflour gibt und wir damit als DJ-Gruppe perfekt harmonieren. Die Energie, die wir tagtäglich dafür investieren, wollen wir bei jeder Gelegenheit an unser Publikum, an EUCH übertragen! Das zeichnet uns aus, dafür stehen wir!   
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Wir sind noch lange nicht am Ende. Freut euch also auf das, was kommt!
          </Typography>
          <Typography variant="h5" marginTop={"4vh"} marginBottom={"2vh"}>
            Tesflour - Jan, Lorenz, Lukas
          </Typography>
        </Box>
      )
    } else if (this.state.language === "en") {
      aboutBrowser = (
        <Box className={classes.bAboutText}>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Our story began in 2014. Even before that, we were able to gather our first experiences in the world of music and decided back then in May to perform as a trio with the name "Tesflour" in the future. Again and again we received questions about the origin of the name. There is no exact answer to that.
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Our sets contain very different genres and subgenres. Especially Deep/Tech/Future, but also UK Bassline, UKG or Drum & Bass play a formative role. In addition to our performances, we have already released some music ourselves.
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            The years have shown that we have grown together as a trio more and more. We are constantly exploring new genres of music and the three of us are more open to many different styles of music. Each of us has his own personal input that we give to Tesflour, which makes us harmonise perfectly as a DJ group. We want to transfer the energy we invest in this every day to our audience, to YOU, at every opportunity! That's what distinguishes us, that's what we stand for!
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            We are still a long way from the end. So look forward to what is to come!
          </Typography>
          <Typography variant="h5" marginTop={"4vh"} marginBottom={"2vh"}>
            Tesflour - Jan, Lorenz, Lukas
          </Typography>
        </Box>
      )
      aboutMobile = (
        <Box className={classes.mAboutText}>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Our story began in 2014. Even before that, we were able to gather our first experiences in the world of music and decided back then in May to perform as a trio with the name "Tesflour" in the future. Again and again we received questions about the origin of the name. There is no exact answer to that.
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            Our sets contain very different genres and subgenres. Especially Deep/Tech/Future, but also UK Bassline, UKG or Drum & Bass play a formative role. In addition to our performances, we have already released some music ourselves.
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            The years have shown that we have grown together as a trio more and more. We are constantly exploring new genres of music and the three of us are more open to many different styles of music. Each of us has his own personal input that we give to Tesflour, which makes us harmonise perfectly as a DJ group. We want to transfer the energy we invest in this every day to our audience, to YOU, at every opportunity! That's what distinguishes us, that's what we stand for!
          </Typography>
          <Typography variant="body1" marginTop={"2vh"} marginBottom={"2vh"}>
            We are still a long way from the end. So look forward to what is to come!
          </Typography>
          <Typography variant="h5" marginTop={"4vh"} marginBottom={"2vh"}>
            Tesflour - Jan, Lorenz, Lukas
          </Typography>
        </Box>
      )
    }

    return(
      <div className={classes.root}>
        <BrowserView className={classes.bRoot}>
          <img src={"/img/logo.png"} alt={"Tesflour Logo"} className={classes.bImageSlide} />
          {aboutBrowser}
        </BrowserView>
        <MobileView className={classes.mRoot}>
          <img src={"/img/logo.png"} alt={"Tesflour Logo"} className={classes.mImageSlide} />
          <Typography variant="h3" fontWeight={200}>About Us</Typography>
          {aboutMobile}
        </MobileView>
      </div>
    )
  }
}

export default withStyles(style)(AboutComponent)