import React, {Component} from "react"
import { BrowserView, MobileView } from "react-device-detect"

import { withStyles } from "@mui/styles"
import { Typography, Box, Button } from "@mui/material"
import { Instagram, YouTube } from "@mui/icons-material"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSoundcloud, faSpotify } from '@fortawesome/free-brands-svg-icons'

const style = theme => ({
  root: {
    height: "100% !important",
    backgroundColor: '#1e1e1e00',
    color: "#ffffff",
  },
  bRoot: {
    height: "100% !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  bImage: {
    paddingTop: "50vh",
    width: "60vw",
    animation: `$slowShow 1000ms ${theme.transitions.easing.easeInOut}`
  },
  bSocialsBox: {
    display: "flex",
    padding: "0px",
    marginLeft: "auto", 
    marginRight: "auto",
    marginTop: theme.spacing(4), 
    border: "2px solid #ffffffc0",
    borderRadius: "8px",
    justifyContent: "space-between",
    alignItems: "center",
    animation: `$slideTop 1500ms ${theme.transitions.easing.easeInOut}`
  },
  bSocialButton: {
    display: "inherit",
    color: "#ffffff",
    margin: 0,
    padding: 0,
    width: "50px",
    height: "50px",
    justifyContent: "center",
    alignItems: "center",
  },
  fontAwesomeIcon: {
    color: "inherit",
  },
  "@keyframes slideTop": {
    "0%": {
      opacity: 0,
      transform: "translateY(-50%)"
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)"
    }
  },
  "@keyframes slowShow": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    }
  },
  mRoot: {
    height: "100% !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  mImage: {
    paddingTop: "45vh",
    width: "90vw",
    animation: `$slowShow 1000ms ${theme.transitions.easing.easeInOut}`
  },
  mSocialsBox: {
    display: "flex",
    padding: "0px",
    marginLeft: "auto", 
    marginRight: "auto",
    marginTop: theme.spacing(2), 
    border: "2px solid #ffffffc0",
    borderRadius: "8px",
    justifyContent: "space-between",
    alignItems: "center",
    animation: `$slideTop 1500ms ${theme.transitions.easing.easeInOut}`
  },
  mSocialButton: {
    display: "inherit",
    color: "#ffffff",
    margin: 0,
    padding: 0,
    width: "50px",
    height: "50px",
    justifyContent: "center",
    alignItems: "center",
  },
})

class HomeComponent extends Component {
  constructor(props) {
    super(props)

    this.onClickInstagram = this.onClickInstagram.bind(this)
    this.onClickYoutube = this.onClickYoutube.bind(this)
    this.onClickSoundcloud = this.onClickSoundcloud.bind(this)
    this.onClickSpotify = this.onClickSpotify.bind(this)
  }

  onClickInstagram() {
    window.open('https://www.instagram.com/tesflourmusic/', '_blank', 'noreferrer');
  }

  onClickYoutube() {
    window.open('https://www.youtube.com/channel/UCfwerKV0idxHa0kLvAwjqYA', '_blank', 'noreferrer');
  }

  onClickSoundcloud() {
    window.open('https://soundcloud.com/tesflour', '_blank', 'noreferrer');
  }

  onClickSpotify() {
    window.open('https://open.spotify.com/artist/0AFxxSt1zKKGGz1gveWaAN?si=H6zMWCecTLagYWIY4mgaPA', '_blank', 'noreferrer');
  }

  render() {
    const {classes} = this.props;

    return(
      <div className={classes.root}>
        <BrowserView className={classes.bRoot}>
          <img src={"/img/logo.png"} alt={"Tesflour Logo"} className={classes.bImage} />
          <Box className={classes.bSocialsBox}> 
            <Button><Typography variant="h5" className={classes.bSocialButton} onClick={this.onClickInstagram} key="instagram" id="instagram"><Instagram fontSize="large" /></Typography></Button>
            <Button><Typography variant="h5" className={classes.bSocialButton} onClick={this.onClickYoutube} key="youtube" id="youtube"><YouTube fontSize="large" /></Typography></Button>
            <Button><Typography variant="h5" className={classes.bSocialButton} onClick={this.onClickSoundcloud} key="soundcloud" id="soundcloud"><FontAwesomeIcon icon={faSoundcloud} className={classes.fontAwesomeIcon}/></Typography></Button>
            <Button><Typography variant="h5" className={classes.bSocialButton} onClick={this.onClickSpotify} key="spotify" id="spotify"><FontAwesomeIcon icon={faSpotify} className={classes.fontAwesomeIcon}/></Typography></Button>
          </Box>
        </BrowserView>
        <MobileView className={classes.mRoot}>
          <img src={"/img/logo.png"} alt={"Tesflour Logo"} className={classes.mImage} />
          <Box className={classes.bSocialsBox}> 
            <Button><Typography variant="h5" className={classes.bSocialButton} onClick={this.onClickInstagram} key="instagram" id="instagram"><Instagram fontSize="large" /></Typography></Button>
            <Button><Typography variant="h5" className={classes.bSocialButton} onClick={this.onClickYoutube} key="youtube" id="youtube"><YouTube fontSize="large" /></Typography></Button>
            <Button><Typography variant="h5" className={classes.bSocialButton} onClick={this.onClickSoundcloud} key="soundcloud" id="soundcloud"><FontAwesomeIcon icon={faSoundcloud} className={classes.fontAwesomeIcon}/></Typography></Button>
            <Button><Typography variant="h5" className={classes.bSocialButton} onClick={this.onClickSpotify} key="spotify" id="spotify"><FontAwesomeIcon icon={faSpotify} className={classes.fontAwesomeIcon}/></Typography></Button>
          </Box>
        </MobileView>
      </div>
    )
  }
}

export default withStyles(style)(HomeComponent)