import React from "react";
import { createTheme, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import 'typeface-roboto'

import './App.css';
import NavbarComponent from "./components/navbar";

function App() {

  const theme = createTheme({
    palette: {
      primary: {main: "#ffffff"},
      secondary: {main: "#1e1e1e"},
      error: {main: "#ff0000"}
    },
  })

  return (
    <div className="AppRoot">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NavbarComponent />
      </ThemeProvider>
    </div>
  );
}

export default App;
